
export const defaultVariantMapping = {
	display1 : "h1",
	display2 : "h1",
	h1       : "h1",
	h2       : "h2",
	h3       : "h3",
	h4       : "h4",
	h5       : "h5",
	h6       : "h6",
	title    : "p",
	label    : "p",
	caption  : "p",
	overline : "p",
	body1    : "p",
	body2    : "p",
	inherit  : "p"
	// subtitle1 : "h6",
	// subtitle2 : "h6",
};

export const fontSizeMapping = {
	display1 : "68px",
	display2 : "42px",
	h1       : "32px",
	h2       : "28px",
	h3       : "24px",
	h4       : "20px",
	h5       : "16px",
	h6       : "12px",
	title    : "22px",
	body1    : "20px",
	body2    : "18px",
	label    : "16px",
	caption  : "14px",
	overline : "12px",
	inherit  : "16px"
};

export const fontWeightMapping = {
	bold   : "500",
	medium : "500",
	normal : "normal"
};
