import { important } from "_common/utils";

import { buttonsColorDark, buttonsColorLight, dark, light, palette } from "./colors";
import cssVariables                                                  from "./cssVariables";
import { defaultVariantMapping, fontSizeMapping, fontWeightMapping } from "./typography";

export const mantineTheme = ( { isApple, themeColorPalette, darkMode, isMobile } ) => ( {
	fontFamily : "Roboto",
	components : {
		Drawer     : drawerStyles (),
		Menu       : menuStyles ( themeColorPalette ),
		Text       : textStyles (),
		Modal      : modalStyles ( themeColorPalette ),
		Popover    : popoverStyles ( themeColorPalette ),
		Button     : buttonStyles (),
		Chip       : chipStyles (),
		Tabs       : tabStyles (),
		ActionIcon : actionIconStyles (),
		List       : listStyles ()
	},
	fontSizes: {
		xxs  : "10px",
		xs   : "12px",
		sm   : "14px",
		md   : "16px",
		lg   : "18px",
		xl   : "20px",
		xxl  : "24px",
		xxxl : "28px"
	},
	colorScheme : darkMode ? "dark" : "light",
	focusRing   : "never",
	other       : {
		...cssVariables,
		...themeLight,
		colors                   : themeColorPalette,
		typographyNew,
		colorScheme              : darkMode ? "dark" : "light",
		darkMode                 : darkMode,
		paletteNew               : palette,
		isApple                  : isApple,
		isMobile                 : isMobile,
		transitionTimingFunction : "cubic-bezier(0,0,0.4,1)",
		breakpoints              : {
			xs   : 0,
			sm   : 600,
			md   : 900,
			lg   : 1200,
			xl   : 1536,
			xxl  : 1900,
			xxxl : 2500
		}
	},
	breakpoints: {
		xs   : "0px",
		sm   : "600px",
		md   : "900px",
		lg   : "1200px",
		xl   : "1536px",
		xxl  : "1900px",
		xxxl : "2500px"
	}
} );

export const themeLight = {
	bgPrimary    : "#f5f5f5",
	bgSecondary  : "#fff",
	borderRadius : "10px"
};

export const themeDark = {
	bgPrimary   : "red",
	bgSecondary : "blue"
};

const typographyNew = {
	defaultVariantMapping, fontSizeMapping, fontWeightMapping
};

function buttonStyles () {
	return {
		styles: {
			root: {
				fontWeight       : "500",
				"&[dataLoading]" : {
					":before": {
						borderRadius: "inherit"
					}
				}
			}
		},
		variants: {
			primary: theme => {
				return {
					root: {
						background         : theme.other.paletteNew.primaryLinearGradient,
						color              : theme.other.paletteNew.secondaryWhite,
						fontWeight         : 500,
						height             : "unset !important",
						padding            : "1rem 2rem",
						borderRadius       : "1rem",
						"&[data-disabled]" : {
							backgroundColor : theme.other.colors.darkBlue50,
							color           : theme.other.colors.white50
						}
					}
				};
			}
		}
	};
}

function textStyles () {
	return {
		styles: {
			label: {
				fontSize: "1.6rem"
			}
		}
	};
}

function menuStyles () {
	return {
		styles: theme => {
			return {
				item: {
					"&[data-hovered]": {
						background: theme.other.colors.background.tertiary
					}
				},
				dropdown: {
					border    : "none",
					boxShadow : theme.other.colors.boxShadow.primary
				}
			};
		}
	};
}
function popoverStyles () {
	return {
		styles: theme => {
			return {
				dropdown: {
					boxShadow  : theme.other.colors.boxShadow.primary,
					background : theme.other.colors.background.primary,
					border     : "none",
					padding    : "unset"
				}
			};
		}
	};
}

function drawerStyles () {
	return {
		styles: {
			body: {
				padding: "unset"
			}
		}
	};
}

function modalStyles () {
	return {
	};
}
function chipStyles () {
	return {
		styles: theme => {
			const chip = theme.other.colors.chip;

			return {
				root: {
					padding: "0rem !important"
				},
				label: {
					padding      : "1.5rem 2rem",
					borderRadius : "1rem",
					border       : important ( `1px solid ${ chip.unchecked.border }` ),
					color        : important ( chip.unchecked.text ),
					background   : chip.unchecked.background,
					":hover"     : {
						background: chip.unchecked.background
					},
					":active": {
						background: chip.unchecked.background
					},

					"&[data-checked]": {
						":not([data-disabled])": {
							border: `1px solid ${ chip.checked.border } !important`
						},
						background : `${ chip.checked.background } !important`,
						border     : `1px solid ${ chip.checked.border } !important`,
						color      : important ( chip.checked.text ),
						padding    : "1.5rem 2rem",

						":hover": {
							background: chip.checked.background
						}
					}

				},
				iconWrapper: {
					display: "none"
				}
			};
		}
	};
}

function actionIconStyles () {

	return {
		styles: theme => {

			return {
				root: {
					":active": {
						background: "none"
						// background: theme.other.colors.primaryBtn.clicked.background
					},
					background: "none"
				}
			};
		}
	};
}

function listStyles () {
	return {
		styles: theme => {
			return {
				itemLabel: {
					width: "100%"
				}
			};
		}
	};
}
function tabStyles () {

	return {
		styles: ( theme, props ) => {
			if ( props?.unstyled )
				return {

				};

			return {
				root: {
				},
				list: {
					flexWrap     : "unset",
					borderBottom : theme.other.darkMode ? undefined : `1px solid ${ theme.other.colors.border.primary }`,
					"::before"   : {
						all: "unset"
					},
					"& > :nth-last-of-type(1)": {
						"::after": {
							transition      : `transform 120ms ${ theme.other.transitionTimingFunction }`,
							width           : "100%",
							content         : "''",
							position        : "absolute",
							bottom          : "0",
							left            : "0%",
							height          : "4px",
							backgroundImage : theme.other.paletteNew.tabGradientRight,
							borderRadius    : "1rem 1rem 0 0",
							zIndex          : "100"
						}
					},
					"& :nth-last-of-type(2)[data-active] ~ :nth-last-of-type(1)": {
						"&::after": {
							transform: "translateX(-100%)"
						}
					},
					"& :nth-last-of-type(3)[data-active] ~ :nth-last-of-type(1)": {
						"&::after": {
							transform: "translateX(-200%)"
						}
					},
					"& :nth-last-of-type(4)[data-active] ~ :nth-last-of-type(1)": {
						"&::after": {
							transform: "translateX(-300%)"
						}
					},
					"& :nth-last-of-type(5)[data-active] ~ :nth-last-of-type(1)": {
						"&::after": {
							transform: "translateX(-400%)"
						}
					},
					"& :nth-last-of-type(6)[data-active] ~ :nth-last-of-type(1)": {
						"&::after": {
							transform: "translateX(-500%)"
						}
					},
					"& :nth-last-of-type(7)[data-active] ~ :nth-last-of-type(1)": {
						"&::after": {
							transform: "translateX(-600%)"
						}
					}
				},
				tab: {
					color            : theme.other.colors.text.secondary,
					fontWeight       : "500",
					borderBottom     : "none",
					flexGrow         : "unset",
					flexBasis        : "100%",
					padding          : "1.6rem 1rem",
					fontSize         : theme.fontSizes.md,
					"&[data-active]" : {
						color: theme.other.paletteNew.primaryDarkBlue
					},
					":hover": {
						background: "initial"
					}
				},
				tabLabel: {
					// textTransform: "uppercase"
				}
			};
		}
	};
}

export {
	buttonsColorDark,
	buttonsColorLight,
	cssVariables,
	dark,
	defaultVariantMapping,
	light,
	palette,
	typographyNew
};
