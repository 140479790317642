
const cssVariables = {
	rooterAvatars: {
		size15: {
			height : "15px",
			width  : "15px"
		},
		size20: {
			height : "20px",
			width  : "20px"
		},
		size30: {
			height : "30px",
			width  : "30px"
		},
		size40: {
			height : "40px",
			width  : "40px"
		},
		size50: {
			height : "50px",
			width  : "50px"
		},
		borderRadius: {
			borderRadius: "100px"
		}
	},
	svgIcons: {
		size10: {
			height : "10px",
			width  : "10px"
		},
		size20: {
			height : "20px",
			width  : "20px"
		},
		size25: {
			height : "25px",
			width  : "25px"
		},
		size30: {
			height : "30px",
			width  : "30px"
		},
		size40: {
			height : "40px",
			width  : "40px"
		},
		size60: {
			height : "60px",
			width  : "60px"
		}
	},
	responsiveImage: {
		width     : "100%",
		height    : "100%",
		objectFit : "contain"
	},
	spacing: {
		"xxs"   : "4px",
		"xs"    : "8px",
		"s"     : "12px",
		"m"     : "16px",
		"l"     : "20px",
		"xl"    : "24px",
		"xxl"   : "28px",
		"xxl-2" : "32px",
		"xxl-3" : "36px",
		"xxl-4" : "40px"
	},
	zIndex: [ 0, 10, 100, 200, 400, 1000, 2000, 5000, 100000 ]
};

export default cssVariables;
